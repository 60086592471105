































































































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import draggable from 'vuedraggable'
import AnnouncementCard from '@/components/announcement/AnnouncementCard.vue'
import DragAndDrop from '@/assets/drag_and_drop.svg'
import { flashResponseError, flashSuccess } from '@/utils/flashMessage'

@Component({
  components: { AnnouncementCard, PageHeader, draggable, DragAndDrop }
})
export default class Announcement extends Vue {
  announcements = [] as announcementData[]
  highlights = [] as announcementData[]
  isArrange = false

  get companyId() {
    return this.$route.params.companyId
  }

  mounted() {
    this.fetchAnnouncements()
  }

  createBtnClicked() {
    this.$router.push(`/company/${this.companyId}/announcement/create`)
  }

  async fetchAnnouncements() {
    this.$http
      .get(`/announcement`, {
        params: {
          companyId: this.companyId,
          skip: 0,
          take: 10,
          orderField: 'createdAt',
          orderBy: 'desc'
        }
      })
      .then((res) => {
        this.announcements = res.data.announcements
        this.highlights = res.data.highlights
      })
      .catch(flashResponseError)
  }

  async highlightAnnouncements() {
    if (this.isArrange) {
      await this.$http
        .post(`/announcement/highlight`, {
          companyId: this.$route.params.companyId,
          announcementIds: this.highlights.map(
            (announcement) => announcement._id
          )
        })
        .then(() => this.fetchAnnouncements())
        .catch(flashResponseError)
    }
    this.isArrange = !this.isArrange
  }

  async deleteAnnouncement(id: string) {
    if (confirm('Are you sure?')) {
      await this.$http
        .delete(`/announcement/${id}`)
        .then(() => {
          flashSuccess()
          this.fetchAnnouncements()
        })
        .catch(flashResponseError)
    }
  }
}

interface announcementData {
  content: string
  createdAt: Date
  image: string[]
  companyId: number
  title: string
  updatedAt: string
  userId: number
  _id: string
  user: {
    address: string
    company_phone_number: string
    createdAt: string
    discardedAt: Date
    email: string
    externalID: string
    faxNumber: string
    firstName: string
    freshChatRestoreID: string
    id: number
    lastName: string
    newsViewCount: number
    number: string
    phoneNumber: string
    postViewCount: number
    state: string
    taxNumber: string
    updatedAt: string
  }
}
