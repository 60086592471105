





















































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import Editor from '@/components/Editor.vue'
import { flashResponseError, flashSuccess } from '@/utils/flashMessage'

@Component({ components: { Editor, PageHeader } })
export default class AnnouncementShow extends Vue {
  announcement = {} as {
    _id: string
    title: string
    content: string
    imagePaths: number[]
    projectId: number
    userId: number
    highlightOrder: number
    createdAt?: string
    updatedAt?: string
  }
  editActive = false
  carouselModel = 0
  mounted() {
    this.fetchAnnouncement()
  }

  fetchAnnouncement() {
    this.$http
      .get(`/announcement/${this.$route.params.id}`)
      .then((res) => {
        this.announcement = res.data
      })
      .catch(flashResponseError)
  }

  cancelClicked() {
    this.editActive = !this.editActive
    this.fetchAnnouncement()
  }

  async saveClicked() {
    delete this.announcement.createdAt
    delete this.announcement.updatedAt
    await this.$http
      .patch(`/announcement/${this.announcement._id}`, this.announcement)
      .then(() => {
        flashSuccess()
        this.$router.go(-1)
      })
      .catch(flashResponseError)
  }

  async deleteAnnouncement() {
    if (confirm('Are you sure?')) {
      await this.$http
        .delete(`/announcement/${this.announcement._id}`)
        .then(() => {
          flashSuccess()
          this.$router.go(-1)
        })
        .catch(flashResponseError)
    }
  }
}
