







































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ components: {} })
export default class UnitEditDialog extends Vue {
  @Prop({
    default: {
      buildingId: 0,
      floorId: 0,
      name: ''
    },
    required: true
  })
  value!: {
    buildingId: number
    floorId: number
    name: string
  }

  @Prop({ default: false }) disabled!: boolean

  buildings = [] as {
    buildingId: string
    buildingName: string
    floorCount: string
    isActive: boolean
  }[]

  floor = [] as {
    floorId: string
    floorName: string
    isActive: boolean
  }[]

  mounted() {
    this.$http
      .get(`/capi/building`, {
        params: {
          projectId: this.$route.params.projectId
        }
      })
      .then((res) => {
        this.buildings = res.data
        if (this.value.floorId) this.buildingChanged()
      })
  }

  buildingChanged() {
    this.$http
      .get(`/capi/floor`, {
        params: {
          buildingId: this.value.buildingId
        }
      })
      .then((res) => {
        this.floor = res.data.map((floor: any) => {
          return {
            floorId: floor.id,
            floorName: floor.floorName,
            isActive: floor.isActive
          }
        })
      })
  }
}
