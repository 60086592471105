














import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ props: ['label', 'value', 'items', 'disabled'] })
export default class Select extends Vue {}
