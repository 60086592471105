






















import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'

@Component({ components: { PageHeader } })
export default class AdminCreate extends Vue {
  adminData = {
    projectId: '',
    firstName: '',
    lastName: '',
    email: ''
  }

  mounted() {
    this.adminData.projectId = this.$route.params.projectId
    if (!this.$route.meta!.isCreate) {
      this.$http.get(`/capi/user/${this.$route.params.adminId}`).then((res) => {
        this.adminData.firstName = res.data.firstName
        this.adminData.lastName = res.data.lastName
        this.adminData.email = res.data.email
      })
    }
  }

  submit() {
    if (this.$route.meta!.isCreate) {
      this.$http
        .post('capi/user/admin', this.adminData)
        .then((res) => {
          this.$router.go(-1)
        })
        .catch((e) => {
          alert(e)
        })
    } else {
      this.$http
        .patch(`capi/user/admin/${this.$route.params.adminId}`, this.adminData)
        .then((res) => {
          this.$router.go(-1)
        })
        .catch((e) => {
          alert(e)
        })
    }
  }
}
