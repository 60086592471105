



















































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import _ from 'lodash'
import { flashResponseError } from '@/utils/flashMessage'
import SelectProjectDropdown from '@/components/project/SelectProjectDropdown.vue'

@Component({ components: { PageHeader, SelectProjectDropdown } })
export default class Admin extends Vue {
  usersList = [] as {
    id: number
    firstName: string
    lastName: string
    email: string
    state: string
    discardedAt: null | string
    number: null | string
    company_phone_number: null | string
    phoneNumber: string
    address: null | string
    faxNumber: null | string
    taxNumber: null | string
    newsViewCount: number
    postViewCount: number
    freshChatRestoreID: null | string
    externalID: string
    createdAt: string
    updatedAt: string
    userTypeId: string
  }[]

  headers = [
    { text: 'id', value: 'id' },
    { text: 'firstName', value: 'firstName' },
    { text: 'lastName', value: 'lastName' },
    { text: 'email', value: 'email' },
    { text: 'state', value: 'state' },
    { text: 'createdAt', value: 'createdAt' },
    { text: 'updatedAt', value: 'updatedAt' },
    { text: 'actions', value: 'actions' }
  ]

  search = ''
  page = 1
  filters = {
    projectId: null
  }

  get companyId() {
    return this.$route.params.companyId
  }

  mounted() {
    this.fetchAdmins()
  }

  fetchAdmins() {
    this.$http
      .get('/capi/user/admin', {
        params: {
          skip: (this.page - 1) * 20,
          take: 20,
          search: this.search,
          filter: { state: 'active' },
          companyId: this.companyId,
          projectId: this.filters.projectId
        }
      })
      .then((res) => {
        this.usersList = res.data as any
      })
      .catch(flashResponseError)
  }

  searchClicked() {
    console.log('search')
  }

  adminCreateBtnClicked() {
    this.$router.push(`/company/${this.companyId}/admin/create`)
  }

  adminEditBtnClicked(item: any) {
    this.$router.push({
      path: `/company/${this.companyId}/admin/${item.id}/edit`,
      params: { item }
    })
  }

  deleteBtnClicked(item: any) {
    if (confirm('Are you sure?')) {
      this.$http
        .patch(`/capi/user/admin/${item.id}`, { state: 'inactive' })
        .then(() => {
          this.usersList = _.remove(this.usersList, (user) => {
            return user.id !== Number.parseInt(item.id)
          })
        })
        .catch((err) => {
          alert(err)
        })
    }
  }
}
