

























import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import { flashResponseError, flashSuccess } from '@/utils/flashMessage'
import CompanyForm from '@/components/form/CompanyForm.vue'
import FormCard from '@/components/FormCard.vue'

@Component({
  components: { PageHeader, CompanyForm, FormCard }
})
export default class CompanyDetail extends Vue {
  company = {
    name: '',
    address: '',
    taxNumber: '',
    remark: ''
  } as { [key: string]: any }

  get companyId() {
    return this.$route.params.companyId
  }

  mounted() {
    this.fetchCompany()
  }

  fetchCompany() {
    this.$http
      .get(`/capi/company/${this.companyId}`)
      .then((res) => (this.company = res.data))
      .catch(flashResponseError)
  }

  async updateCompany() {
    if (!confirm('Are you sure you want to edit this company ?')) return false

    const formData = new FormData()
    for (let key in this.company) {
      formData.append(key, this.company[key])
    }

    await this.$http
      .patch(`/capi/company/${this.companyId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        flashSuccess()
        this.$router.go(-1)
      })
      .catch(flashResponseError)
  }
}
