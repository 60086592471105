import store from '@/store'
import firebase from 'firebase/app'
import http from '@/http-no-firebase'
import { refreshSTSToken } from '@/utils/objectStorageService'
export const login = async () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        try {
          const token = await user?.getIdToken()
          const tokenDetail = await user?.getIdTokenResult()
          store.commit('setToken', {
            token,
            expiration: tokenDetail.expirationTime
          })
          const userResponse = await http.get('/capi/user/current')
          console.log('set', userResponse)
          store.commit('setUser', userResponse.data)
          await refreshSTSToken()
          resolve(true)
        } catch (e) {
          reject(e)
        }
      } else {
        console.log('no user')
        reject(false)
      }
    })
  })
}
export const refreshFirebaseToken = () => {
  return new Promise((resolve, reject) => {
    if (
      !store.state.firebase.token ||
      new Date() > new Date(store.state.firebase.expiration)
    ) {
      login()
        .then(() => {
          resolve(true)
        })
        .catch(e => {
          reject(e)
        })
    } else {
      resolve(true)
    }
  })
}
