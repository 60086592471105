























































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination.vue'
import axios from '@/http-no-loading'
import BuildingDialog from '@/views/unit/dialog/BuildingDialog.vue'

@Component({ components: { BuildingDialog, Pagination }, props: ['projectId'] })
export default class BuildingTab extends Vue {
  @Prop(Number) readonly projectId: number | undefined

  buildingList = [] as {
    buildingId: string
    buildingName: string
    floorCount: string
    isActive: boolean
  }[]
  headers = [
    { text: 'buildingName', value: 'buildingName', width: '20%' },
    { text: 'floorCount', value: 'floorCount', width: '20%' },
    { text: 'isActive', value: 'isActive', width: '20%' },
    { text: 'actions', value: 'actions', width: '20%' }
  ]
  loadingActive = false
  search = ''
  page = 1

  buildingDialog = {
    disabled: false,
    isActive: false,
    isCreate: false,
    data: {}
  }

  async mounted() {
    await this.getData()
  }
  async getData() {
    await this.$http
      .get('/capi/building', {
        params: {
          projectId: this.projectId,
          skip: (this.page - 1) * 10,
          take: 10,
          search: this.search,
          filter: { date: 0 }
        }
      })
      .then((res) => {
        this.buildingList = res.data as any
      })
  }

  createBtnClicked() {
    this.buildingDialog = {
      disabled: false,
      isActive: true,
      isCreate: true,
      data: {}
    }
  }

  editBtnClicked(item: any) {
    this.buildingDialog = {
      disabled: false,
      isActive: true,
      isCreate: false,
      data: item
    }
  }

  async deleteBtnClicked(itemId: string) {
    if (confirm('Are you sure?')) {
      await this.$http
        .patch(`/capi/building/disable/${itemId}`)
        .then(() => {
          this.getData()
        })
        .catch((err) => {
          alert(err)
        })
    }
  }

  searchClicked() {
    this.getData()
  }

  buildingSubmit(data: any) {
    if (this.buildingDialog.isCreate) {
      //  create unit
      this.$http
        .post(`capi/building`, data, {
          params: {
            projectId: this.$route.params.projectId
          }
        })
        .then(() => {
          this.buildingDialog.isActive = false
          this.getData()
        })
        .catch((err) => {
          alert(err)
        })
    } else {
      //  update unit
      this.$http
        .patch(`capi/building/update/${data.buildingId}`, {
          name: data.name
        })
        .then(() => {
          this.buildingDialog.isActive = false
          this.getData()
        })
        .catch((err) => {
          alert(err)
        })
    }
  }
}
