



















































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import UploadImages from 'vue-upload-drop-images'
import {
  EditableField,
  FormGroup,
  EditableTextareaField
} from '@/components/field'

@Component({
  components: {
    PageHeader,
    UploadImages,
    FormGroup,
    EditableTextareaField,
    EditableField
  },
  props: {
    company: {
      type: Object
    },
    edit: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    }
  }
})
export default class CompanyForm extends Vue {}
