

































































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import axios from '@/http-no-loading'
import Pagination from '@/components/Pagination.vue'
import _ from 'lodash'
import FloorDialog from '@/views/unit/dialog/FloorDialog.vue'
import draggable from 'vuedraggable'
import DragAndDrop from '@/assets/drag_and_drop.svg'
@Component({
  components: { FloorDialog, Pagination, draggable, DragAndDrop },
  props: ['projectId']
})
export default class FloorTab extends Vue {
  @Prop(Number) readonly projectId: number | undefined

  isArrange = false
  buildingList = [] as {
    buildingId: string
    buildingName: string
    floorCount: string
    isActive: boolean
  }[]
  buildingData = {
    id: 0,
    name: ''
  }
  floorDialog = {
    disabled: false,
    isActive: false,
    isCreate: false,
    data: {}
  }
  floorList = []
  headers = [
    { text: 'Floor Level', value: 'index', width: '20%' },
    { text: 'buildingName', value: 'buildingName', width: '20%' },
    { text: 'floorName', value: 'floorName', width: '20%' },
    { text: 'actions', value: 'actions', width: '20%' }
  ]
  loadingActive = false
  search = ''
  page = 1

  async mounted() {
    await this.getBuildingData()
  }

  async getBuildingData() {
    this.floorList = []

    await this.$http
      .get('/capi/building', {
        params: {
          projectId: this.projectId,
          skip: 0,
          take: 100
        }
      })
      .then((res) => {
        this.buildingList = res.data as any
      })
  }

  buildingChanged() {
    this.buildingData.name =
      _.find(this.buildingList, (v: any) => {
        return v.buildingId === this.buildingData.id
      })?.buildingName || ''
    this.getData()
  }

  async getData() {
    await this.$http
      .get(`/capi/floor`, {
        params: {
          buildingId: this.buildingData.id
        }
      })
      .then((res) => {
        this.floorList = res.data as any
      })
  }

  @Emit('showUnit')
  detailsBtnClicked(item: any) {
    return item
  }

  async deleteBtnClicked(itemId: string) {
    if (confirm('Are you sure?')) {
      await this.$http
        .patch(`/capi/floor/${itemId}/disable`)
        .then(() => {
          this.getData()
        })
        .catch((err) => {
          alert(err)
        })
    }
  }
  createBtnClicked() {
    this.floorDialog = {
      disabled: false,
      isActive: true,
      isCreate: true,
      data: {}
    }
  }
  searchClicked() {
    this.getData()
  }
  floorSubmit(data: any) {
    if (this.floorDialog.isCreate) {
      //  create unit
      this.$http
        .post(`capi/floor`, data, {
          params: {
            buildingId: data.buildingId
          }
        })
        .then(() => {
          this.floorDialog.isActive = false
          this.getData()
        })
        .catch((err) => {
          alert(err)
        })
    } else {
      //  update unit
      this.$http
        .patch(`capi/floor/${data.unitId}/update`, {
          buildingFloor: data.floorId,
          name: data.name
        })
        .then(() => {
          this.floorDialog.isActive = false
          this.getData()
        })
        .catch((err) => {
          alert(err)
        })
    }
  }
  saveBtnClicked() {
    if (this.isArrange) {
      console.log('log', this.floorList)
      this.$http.patch('capi/floor/update_floor_level', this.floorList, {
        params: { buildingId: this.buildingData.id }
      })
      this.isArrange = false
    } else {
      this.isArrange = true
    }
  }
}
