


















import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import BuildingForm from '@/components/form/BuildingForm.vue'

@Component({ components: { BuildingForm } })
export default class BuildingDialog extends Vue {
  @Prop({ default: false }) value!: boolean
  @Prop({ default: false }) disabled!: boolean
  @Prop({})
  data!: {
    buildingId: number
    buildingName: string
  }

  formData = {} as {
    buildingId: number
    name: string
  }

  mounted() {
    this.formData = {
      buildingId: this.data.buildingId,
      name: this.data.buildingName
    }
  }

  @Emit('submit')
  submitClicked() {
    return this.formData
  }
  @Emit('input')
  closeClicked() {
    return !this.value
  }
}
