




























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({ components: {} })
export default class RentalContractUserRoleEditDialog extends Vue {
  @Prop({
    default: false,
    required: true
  })
  value!: boolean

  @Prop({ default: -1, required: true }) rentalContractUserId!: number
  @Prop({ required: true }) userId!: number

  isActive = this.value

  roles = []
  roleSelect = 0

  mounted() {
    this.$http
      .get('/capi/role', {
        params: { rentalContractId: this.$route.params.rentalContractId }
      })
      .then((res) => {
        this.roles = res.data
      })
  }

  saveClicked() {
    this.$http
      .patch('/capi/role/rental_contract_user_role', {
        rentalContractId: this.$route.params.rentalContractId,
        roleId: this.roleSelect,
        userId: this.userId
      })
      .then((res) => {
        console.log(res)
      })
      .finally(() => {
        this.$emit('reloadData')
        this.closeClicked()
      })
  }

  closeClicked() {
    this.$emit('input', false)
  }

  @Watch('value')
  changed() {
    this.isActive = this.value
  }
}
