












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ props: ['value', 'edit'] })
export default class EditableField extends Vue {
  @Prop() value?: any
  @Prop({ default: false }) edit?: any
}
