





import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

@Component({ components: { quillEditor } })
export default class Editor extends Vue {
  @Prop({ default: '', required: true }) value!: string

  // content = '<h2>Example</h2>'
  editorOption = {
    modules: {
      toolbar: [
        [
          'bold',
          'italic',
          'underline',
          'strike',
          { list: 'bullet' },
          { list: 'ordered' },
          'link'
        ]
      ]
    }
  }
  text = ''

  mounted() {
    this.text = this.value
  }

  @Watch('text')
  changed() {
    this.$emit('input', this.text)
  }
}
