

































































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ components: {} })
export default class AnnouncementCard extends Vue {
  @Prop() value!: announcementData

  detailsBtnClicked() {
    this.$router.push(`announcement/${this.value._id}`)
  }

  handleClickDelete(_id: string) {
    this.$emit('delete', _id)
  }
}

interface announcementData {
  content: string
  createdAt: Date
  imagePaths: string[]
  projectId: number
  title: string
  updatedAt: string
  userId: number
  _id: string
  user: {
    address: string
    company_phone_number: string
    createdAt: string
    discardedAt: Date
    email: string
    externalID: string
    faxNumber: string
    firstName: string
    freshChatRestoreID: string
    id: number
    lastName: string
    newsViewCount: number
    number: string
    phoneNumber: string
    postViewCount: number
    state: string
    taxNumber: string
    updatedAt: string
  }
}
