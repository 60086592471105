






































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import BaseImage from '@/components/BaseImage.vue'
import http from '@/http'
@Component({
  components: {
    BaseImage,
    PageHeader
  }
})
export default class Home extends Vue {
  companies = [] as {
    address: string
    companyId: string
    companyName: string
    invoiceRemark: string
    invoiceRemark2: string
    invoiceRemark3: string
    taxNumber: string
  }[]
  mounted() {
    http.get('/capi/company').then((res) => {
      console.log(res)
      this.companies = res.data
    })
  }
}
