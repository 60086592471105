























































import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import axios from '@/http-no-loading'
import Pagination from '@/components/Pagination.vue'
import UnitDialog from '@/views/unit/dialog/UnitDialog.vue'

@Component({ components: { UnitDialog, Pagination }, props: ['projectId'] })
export default class UnitTab extends Vue {
  @Prop(Number) readonly projectId: number | undefined

  unitList = [] as {
    buildingId: string
    buildingName: string
    currentRentalContract: string
    floorId: string
    floorName: string
    isActive: boolean
    unitId: string
    unitName: string
  }[]
  headers = [
    { text: 'unitName', value: 'unitName', width: '20%' },
    { text: 'floorName', value: 'floorName', width: '20%' },
    { text: 'buildingName', value: 'buildingName', width: '20%' },
    {
      text: 'RentalContract',
      value: 'currentRentalContract',
      width: '10%'
    },
    { text: 'isActive', value: 'isActive', width: '10%' },
    { text: 'actions', value: 'actions', width: '15%' }
  ]

  loadingActive = false
  search = ''
  page = 1

  unitDialog = {
    disabled: false,
    isActive: false,
    isCreate: false,
    data: {}
  }

  async mounted() {
    await this.getData()
  }

  async getData() {
    await this.$http
      .get('/capi/unit/', {
        params: {
          projectId: this.projectId,
          skip: (this.page - 1) * 10,
          take: 10,
          search: this.search,
          filter: { date: 0 }
        }
      })
      .then((res) => {
        this.unitList = res.data as any
      })
  }

  createBtnClicked() {
    this.unitDialog = {
      disabled: false,
      isActive: true,
      isCreate: true,
      data: {}
    }
  }

  editBtnClicked(item: any) {
    this.unitDialog = {
      disabled: false,
      isActive: true,
      isCreate: false,
      data: item
    }
  }

  deleteBtnClicked(itemId: string) {
    if (confirm('Are you sure?')) {
      this.$http
        .patch(`/capi/unit/${itemId}`, {
          isActive: false,
          deletedAt: new Date()
        })
        .then(() => {
          this.getData()
        })
        .catch((err) => {
          alert(err)
        })
    }
  }

  unitSubmit(data: any) {
    if (this.unitDialog.isCreate) {
      //  create unit
      this.$http
        .post(`capi/unit/`, data, {
          params: {
            floorId: data.floorId
          }
        })
        .then(() => {
          this.unitDialog.isActive = false
          this.getData()
        })
        .catch((err) => {
          alert(err)
        })
    } else {
      //  update unit
      this.$http
        .patch(`capi/unit/${data.unitId}`, {
          buildingFloor: data.floorId,
          name: data.name
        })
        .then(() => {
          this.unitDialog.isActive = false
          this.getData()
        })
        .catch((err) => {
          alert(err)
        })
    }
  }
}
