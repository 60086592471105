import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

interface State {
  loading: boolean[]
  loadingActive: boolean
  user: any
  firebase: {
    token: string
    expiration: string
  }
  ossSTSToken: {
    AccessKeyId: string
    AccessKeySecret: string
    SecurityToken: string
    Expiration: string
  }
  ossClient: any
  companies: any[]
  currentCompany: {
    id: number
    name: string
    type: string
    banner_url: string
  }
  currentProject: {
    id: number
    name: string
    type: string
    banner_url: string
  }
}

export default new Vuex.Store({
  state: {
    loading: [] as boolean[],
    loadingActive: false as boolean,
    user: {},
    firebase: {
      token: '',
      expiration: ''
    },
    ossSTSToken: {
      AccessKeyId: '',
      AccessKeySecret: '',
      SecurityToken: '',
      Expiration: ''
    },
    ossClient: {
      signatureUrl(src: string) {
        return Promise.resolve(undefined)
      }
    },
    companies: [],
    currentCompany: {
      id: -1,
      name: '',
      type: '',
      banner_url: ''
    },
    currentProject: {
      id: -1,
      name: '',
      type: '',
      banner_url: ''
    }
  } as State,
  mutations: {
    setUser: function (state: any, user) {
      state.user = user
    },
    setToken: function (state, { token, expiration }) {
      state.firebase.token = token
      state.firebase.expiration = expiration
    },
    updateLoading: function (state, isAdd) {
      if (isAdd) {
        state.loading.push(true)
        state.loadingActive = true
      } else {
        state.loading.pop()

        if (state.loading.length == 0) state.loadingActive = false
      }
    },
    forceStopLoading: function (state) {
      state.loading = []
      state.loadingActive = false
    },
    companiesLoaded: function (state: State, companies: any[]) {
      state.companies = companies
    }
  },
  actions: {
    setUser: function ({ commit }, user) {
      commit('setUser', user)
    },
    setToken: function ({ commit }, token) {
      commit('setToken', token)
    },
    startLoading: function ({ commit }, token) {
      commit('updateLoading', true)
    },
    stopLoading: function ({ commit }, token) {
      commit('updateLoading', false)
    },
    forceStopLoading: function ({ commit }, token) {
      commit('forceStopLoading')
    },
    companiesLoaded: function ({ commit }, companies) {
      commit('companiesLoaded', companies)
    }
  },
  modules: {},
  getters: {
    projects: (state: State) => (companyId: number) => {
      const company = state.companies.find(
        (company: any) => company.id == companyId
      )

      return company?.projects || []
    }
  }
})
