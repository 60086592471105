







































































































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import axios from '@/http'

@Component({
  components: { PageHeader }
})
export default class FailedRecord extends Vue {
  menuEndDate = false
  menuStartDate = false

  filters = {
    startDate: null,
    endDate: null
  }

  async downloadRecord() {
    try {
      const response = await axios.get('/invoice_record', {
        params: {
          startDate: this.filters.startDate,
          endDate: this.filters.endDate,
          type: 'csv'
        }
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `invoice_record-${this.filters.startDate}--${this.filters.endDate}.csv`
      )
      document.body.appendChild(link)
      link.click()
    } catch (e) {
      alert('Something went wrong. Please try again later.')
    }
  }

  clearSelected() {
    this.filters = {
      startDate: null,
      endDate: null
    }
  }
}
