





























import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import CompanyForm from '@/components/form/CompanyForm.vue'
import { flashResponseError } from '@/utils/flashMessage'
import FormCard from '@/components/FormCard.vue'

@Component({
  components: {
    PageHeader,
    FormCard,
    CompanyForm
  }
})
export default class CompanyDetail extends Vue {
  company: any = {}

  get companyId() {
    return this.$route.params.companyId
  }

  mounted() {
    this.fetchCompany()
  }

  fetchCompany() {
    this.$http
      .get(`/capi/company/${this.companyId}`)
      .then((res) => (this.company = res.data))
      .catch(flashResponseError)
  }
}
