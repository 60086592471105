











import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({ components: {} })
export default class UserForm extends Vue {
  @Prop({
    default: {
      projectId: '',
      firstName: '',
      lastName: '',
      phoneNumber: '+66',
      email: ''
    },
    required: true
  })
  value!: {
    projectId: string
    firstName: string
    lastName: string
    phoneNumber: string
    email: string
  }

  @Watch('value')
  changed() {
    this.$emit('input', this.value)
  }
}
