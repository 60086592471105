










import { Component, Prop, Vue } from 'vue-property-decorator'
import Label from './Label.vue'

@Component({ props: ['required', 'label'], components: { Label } })
export default class FormGroup extends Vue {
  @Prop({
    default: {
      label: ''
    },
    required: true
  })
  value!: {
    label: string
  }
}
