


































import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import Editor from '@/components/Editor.vue'
import PageHeader from '@/components/PageHeader.vue'
import { flashResponseError } from '@/utils/flashMessage'
import UploadImages from 'vue-upload-drop-images'
import SelectProjectDropdown from '@/components/project/SelectProjectDropdown.vue'

@Component({
  components: { PageHeader, Editor, UploadImages, SelectProjectDropdown }
})
export default class NewsCreate extends Vue {
  form = {
    title: '',
    content: '',
    images: [],
    projectId: ''
  }

  handleImages(files: any) {
    console.log('handled', files)
    this.form.images = files
  }

  createNews() {
    // const imageDom: any = document.querySelector('#file')
    const projectId = this.form.projectId

    const formData = new FormData()
    formData.append('title', this.form.title)
    formData.append('content', this.form.content)
    for (let i = 0; i < this.form.images.length; i++) {
      formData.append('images', this.form.images[i])
    }
    formData.append('projectId', projectId)

    this.$http
      .post('/news', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        this.$router.replace(
          `/company/${this.$route.params.companyId}/news/${res.data._id}`
        )
      })
      .catch(flashResponseError)
  }
}
