

















import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'

@Component({
  components: {
    PageHeader
  }
})
export default class FormCard extends Vue {}
