


















import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import UnitForm from '@/components/form/UnitForm.vue'
import FloorForm from '@/components/form/FloorForm.vue'

@Component({ components: { FloorForm, UnitForm } })
export default class FloorDialog extends Vue {
  @Prop({ default: false }) value!: boolean
  @Prop({ default: false }) disabled!: boolean
  @Prop({
    default: {
      unitId: 0,
      buildingId: 0,
      floorId: 0,
      unitName: ''
    }
  })
  data!: {
    unitId: number
    buildingId: number
    floorId: number
    unitName: string
  }

  formData = {} as {
    buildingId: number
    unitId: number
    floorId: number
    name: string
  }

  mounted() {
    this.formData = {
      unitId: this.data.unitId,
      buildingId: this.data.buildingId,
      floorId: this.data.floorId,
      name: this.data.unitName
    }
  }

  @Emit('submit')
  submitClicked() {
    return this.formData
  }

  @Emit('input')
  closeClicked() {
    return !this.value
  }
}
