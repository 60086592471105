














































import { Component, Vue, Ref } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import UnitTab from '@/views/unit/tabs/UnitTab.vue'
import BuildingTab from '@/views/unit/tabs/BuildingTab.vue'
import FloorTab from '@/views/unit/tabs/FloorTab.vue'
import SelectProjectDropdown from '@/components/project/SelectProjectDropdown.vue'

@Component({
  components: {
    FloorTab,
    BuildingTab,
    UnitTab,
    PageHeader,
    SelectProjectDropdown
  }
})
export default class Unit extends Vue {
  @Ref('buildingTab') readonly buildingTab!: BuildingTab
  @Ref('floorTab') readonly floorTab!: FloorTab
  @Ref('unitTab') readonly unitTab!: UnitTab

  filters = {
    projectId: null
  }

  tab = 0

  createBtnClicked() {
    switch (this.tab) {
      case 0:
        this.buildingTab.createBtnClicked()
        break
      case 1:
        this.floorTab.createBtnClicked()
        break
      case 2:
        this.unitTab.createBtnClicked()
        break
    }
  }

  async fetchData() {
    await setTimeout(() => {}, 2000)
    switch (this.tab) {
      case 0:
        this.buildingTab.getData()
        break
      case 1:
        this.floorTab.getBuildingData()
        break
      case 2:
        this.unitTab.getData()
        break
    }
  }
}
