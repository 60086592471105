export const toFloat = (val: any, decimal = 2) => {
  return parseFloat(val).toFixed(decimal)
}

export const toMoney = (val: any) => {
  return new Intl.NumberFormat('th-TH', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(val)
}

export const forceNumberAndPercent = (value: any) => {
  if (value.includes('%')) {
    if (isNaN(value.replace('%', ''))) {
      console.log('toflatt !! AAAA ')
      return '0.00'
    }

    if (isNaN(toFloat(value) as any)) {
      return '0.00'
    }

    return toFloat(value) + '%'
  }

  return forceNumber(value)
}

export const forceNumber = (value: any) => {
  if (isNaN(value)) {
    return '0.00'
  }

  if (isNaN(toFloat(value) as any)) {
    return '0.00'
  }

  return toFloat(value)
}
