




import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {},
  props: ['state']
})
export default class UserState extends Vue {
  @Prop() state!: any

  capitalizeFirstLetter(str = ' ') {
    return str.replace(/^./, str[0].toUpperCase())
  }
}
