


































































































































































import { Component, Mixins } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import BillState from '@/components/tag/BillState'
import PaginationMixin from '@/mixins/pagination'
import SelectProjectDropdown from '@/components/project/SelectProjectDropdown.vue'

@Component({
  components: { PageHeader, BillState, SelectProjectDropdown },
  mixins: [PaginationMixin]
})
export default class Invoice extends Mixins(PaginationMixin) {
  search = ''

  menuDueAt = false
  menuIssueAt = false

  filters = {
    dueAtByDay: true, // filter dueAt with day-by-day condition
    dueAt: '',
    issueAt: '',
    state: '',
    projectId: null
  }

  filterOptions = {
    state: [
      {
        text: 'Pending',
        value: 'pending'
      },
      {
        text: 'Paid',
        value: 'paid'
      },
      {
        text: 'Voided',
        value: 'voided'
      }
    ]
  }

  headers = [
    { text: 'Number', value: 'number' },
    { text: 'Due date', value: 'dueAt' },
    { text: 'Issude date', value: 'createdAt' },
    { text: 'State', value: 'state' },
    { text: 'Unit', value: 'invoiceUnit.unitName' },
    { text: 'Building', value: 'invoiceUnit.buildingName' },
    { text: 'Grand Total', value: 'grandTotalAfterCreditNoteCents' },
    { text: 'Action', value: 'actions' }
  ]

  meta = {}

  invoices = []

  get companyId() {
    return this.$route.params.companyId
  }

  mounted() {
    this.fetchInvoices()
  }

  fetchInvoices() {
    this.$http
      .get('/invoice', {
        params: {
          search: this.search,
          page: this.page,
          perPage: this.perPage,
          state: this.filters.state,
          issueAt: this.filters.issueAt,
          dueAt: this.filters.dueAt,
          projectId: this.filters.projectId,
          companyId: this.companyId
        }
      })
      .then((res) => {
        this.setPagination(res.data.meta)
        this.invoices = res.data.data
      })
  }

  toCreatePage() {
    this.$router.push(`/company/${this.companyId}/invoice/create`)
  }

  toDetailsPage(id: number) {
    this.$router.push(`/company/${this.companyId}/invoice/${id}`)
  }
}
