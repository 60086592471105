






































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import Editor from '@/components/Editor.vue'
import UploadImages from 'vue-upload-drop-images'
import { flashResponseError, flashSuccess } from '@/utils/flashMessage'

@Component({ components: { PageHeader, Editor, UploadImages } })
export default class AnnouncementCreate extends Vue {
  announcement = {
    title: '',
    content: ''
  }

  images = []

  handleImages(files: any) {
    this.images = files
  }

  createBtnClicked() {
    const formData = new FormData()
    formData.append('title', this.announcement.title)
    formData.append('content', this.announcement.content)
    formData.append('companyId', this.$route.params.companyId)
    for (let i = 0; i < this.images.length; i++) {
      formData.append('images', this.images[i])
    }

    this.$http
      .post('/announcement', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        flashSuccess()
        this.$router.go(-1)
      })
      .catch(flashResponseError)
  }
}
