













import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ props: ['value', 'edit'] })
export default class EditableTextareaField extends Vue {}
