

























































































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import IconPencil from '@/assets/icon_pencil.svg'

@Component({ components: { PageHeader, IconPencil } })
export default class Overview extends Vue {
  overviewData = {
    activeUsersCount: 'XX',
    pendingPostCount: 'XX',
    projectId: 'XX',
    unpaidBillsCount: 'XX'
  }

  company: any = {}

  get companyId() {
    return this.$route.params.companyId
  }

  get projects() {
    return this.$store.getters.projects(this.companyId)
  }

  async mounted() {
    this.fetchCompany()
  }

  fetchCompany() {
    this.$http
      .get(`/capi/company/${this.companyId}`)
      .then((res) => (this.company = res.data))
  }
}
