import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '../store'
import { login } from '@/utils/firebase'

import Overview from '@/views/Overview.vue'
import News from '@/views/company/[id]/news/index.vue'
import NewsCreate from '@/views/company/[id]/news/create.vue'
import NewsDetails from '@/views/company/[id]/news/[id]/index.vue'

import Post from '@/views/Post.vue'
import Login from '@/views/Login.vue'
import Home from '@/views/Home.vue'

import CompanyCreate from '@/views/company/create.vue'
import CompanyDetail from '@/views/company/[id]/detail.vue'
import CompanyEdit from '@/views/company/[id]/edit.vue'

import ProjectCreate from '@/views/project/create.vue'

import Unit from '@/views/unit/Unit.vue'
import UnitCreate from '@/views/unit/UnitCreate.vue'

import RentalContract from '@/views/rentalContract/RentalContract.vue'
import RentalContractCreate from '@/views/rentalContract/RentalContractCreate.vue'
import RentalContractShow from '@/views/rentalContract/RentalContractShow.vue'

import Invoice from '@/views/company/[id]/invoice'
import InvoiceCreate from '@/views/company/[id]/invoice/create.vue'
import InvoiceDetails from '@/views/company/[id]/invoice/[id]'

import Admin from '@/views/admin/Admin.vue'
import AdminCreate from '@/views/admin/AdminCreate.vue'

import User from '@/views/user/User.vue'
import UserCreate from '@/views/user/UserCreate.vue'
import UserShow from '@/views/user/UserShow.vue'

import Announcement from '@/views/company/[id]/announcement'
import AnnouncementCreate from '@/views/company/[id]/announcement/create.vue'
import AnnouncementShow from '@/views/company/[id]/announcement/[id]'

import PaymentMethod from '@/views/PaymentMethod.vue'
import FailedRecord from '@/views/company/[id]/failedRecord/index.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/company/create',
    name: 'CompanyCreate',
    component: CompanyCreate
  },
  {
    path: '/company/:companyId/detail',
    name: 'Company Detail',
    component: CompanyDetail
  },
  {
    path: '/company/:companyId/edit',
    name: 'Company Edit',
    component: CompanyEdit
  },
  {
    path: '/company/:companyId/project/create',
    name: 'ProjectCreate',
    component: ProjectCreate
  },
  {
    path: '/company/:companyId/overview',
    name: 'Overview',
    component: Overview,
    meta: {
      nav: true
    }
  },
  /***
   * CMS
   */
  {
    path: '/company/:companyId/failedRecords',
    name: 'FailedRecords',
    component: FailedRecord
  },
  {
    path: '/company/:companyId/announcement',
    name: 'Announcement',
    component: Announcement
  },
  {
    path: '/company/:companyId/announcement/create',
    name: 'Announcement Create',
    component: AnnouncementCreate
  },
  {
    path: '/company/:companyId/announcement/:id',
    name: 'Announcement Show',
    component: AnnouncementShow
  },
  {
    path: '/company/:companyId/news',
    name: 'News',
    component: News,
    meta: {
      nav: true
    }
  },
  {
    path: '/company/:companyId/news/create',
    name: 'News Create',
    component: NewsCreate,
    meta: {
      nav: true
    }
  },
  {
    path: '/company/:companyId/news/:newsId',
    name: 'NewsDetails',
    component: NewsDetails,
    meta: {
      nav: true
    }
  },

  {
    path: '/company/:companyId/post',
    name: 'Post',
    component: Post,
    meta: {
      nav: true
    }
  },
  /***
   * CRM
   */
  {
    path: '/company/:companyId/unit',
    name: 'Unit',
    component: Unit,
    meta: {
      nav: true
    }
  },
  {
    path: '/company/:companyId/unit/create',
    name: 'UnitCreate',
    component: UnitCreate,
    meta: {
      nav: true
    }
  },
  {
    path: '/company/:companyId/rental_contract',
    name: 'RentalContract',
    component: RentalContract,
    meta: {
      nav: true
    }
  },
  {
    path: '/company/:companyId/rental_contract/create',
    name: 'RentalContractCreate',
    component: RentalContractCreate,
    meta: {
      nav: true
    }
  },
  {
    path: '/company/:companyId/rental_contract/:rentalContractId',
    name: 'RentalContractShow',
    component: RentalContractShow,
    meta: {
      nav: true
    }
  },
  {
    path: '/company/:companyId/invoice',
    name: 'Invoice',
    component: Invoice,
    meta: {
      nav: true
    }
  },
  {
    path: '/company/:companyId/invoice/create',
    name: 'Invoice Create',
    component: InvoiceCreate,
    meta: {
      nav: true
    }
  },
  {
    path: '/company/:companyId/invoice/:invoiceId',
    name: 'InvoiceDetails',
    component: InvoiceDetails
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/company/:companyId/admin',
    name: 'admin',
    component: Admin,
    meta: {
      nav: true
    }
  },
  {
    path: '/company/:companyId/admin/create',
    name: 'admin create',
    component: AdminCreate,
    meta: {
      nav: true,
      title: 'Admin Create',
      subtitle: 'Admin Create',
      isCreate: true
    }
  },
  {
    path: '/company/:companyId/admin/:adminId/edit',
    name: 'Admin Edit',
    component: AdminCreate,
    meta: {
      nav: true,
      title: 'Admin Edit',
      subtitle: 'Admin Edit',
      isCreate: false
    }
  },
  {
    path: '/company/:companyId/user',
    name: 'user',
    component: User,
    meta: {
      nav: true
    }
  },
  {
    path: '/company/:companyId/user/create',
    name: 'user create',
    component: UserCreate,
    meta: {
      nav: true
    }
  },
  {
    path: '/company/:companyId/user/:userId',
    name: 'user show',
    component: UserShow,
    meta: {
      nav: true
    }
  },
  {
    path: '/company/:companyId/paymentMethod',
    name: 'payment method setting',
    component: PaymentMethod,
    meta: {
      nav: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(async (to, from, next) => {
  if (
    store.state.user.id &&
    store.state.firebase.token &&
    new Date() < new Date(store.state.firebase.expiration)
  ) {
    next()
  } else {
    await login()
      .then(async (res) => {
        if (res) {
          if (to.path === '/login') {
            await router.push('/')
            next()
          } else {
            next()
          }
        }
      })
      .catch(async (e) => {
        if (to.path !== '/login') {
          await router.push('/login')
          next()
        } else {
          next()
        }
      })
  }
})

export default router
