var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"title":"Bill","with-back-btn":true}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.invoices,"page":_vm.page,"items-per-page":_vm.perPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('SelectProjectDropdown',{on:{"input":_vm.fetchInvoices},model:{value:(_vm.filters.projectId),callback:function ($$v) {_vm.$set(_vm.filters, "projectId", $$v)},expression:"filters.projectId"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticStyle:{"float":"right"}},[_c('v-text-field',{staticStyle:{"width":"200px","display":"inline-block","margin-right":"15px"},attrs:{"outlined":"","append-icon":"fas fa-search","dense":"","hide-details":""},on:{"click:append":_vm.fetchInvoices,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchInvoices.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticStyle:{"display":"inline-block"},attrs:{"color":"primary"},on:{"click":_vm.toCreatePage}},[_vm._v("create")])],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"transition":"scale-transition","min-width":"auto","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Due date","prepend-icon":"mdi-calendar","hide-details":"","readonly":"","outlined":"","dense":""},model:{value:(_vm.filters.dueAt),callback:function ($$v) {_vm.$set(_vm.filters, "dueAt", $$v)},expression:"filters.dueAt"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDueAt),callback:function ($$v) {_vm.menuDueAt=$$v},expression:"menuDueAt"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.filters.dueAt),callback:function ($$v) {_vm.$set(_vm.filters, "dueAt", $$v)},expression:"filters.dueAt"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function () {
                        _vm.menuDueAt = false
                        _vm.filters.dueAt = null
                      }}},[_vm._v(" Clear ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"transition":"scale-transition","min-width":"auto","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Issue Date","prepend-icon":"mdi-calendar","hide-details":"","readonly":"","outlined":"","dense":""},model:{value:(_vm.filters.issueAt),callback:function ($$v) {_vm.$set(_vm.filters, "issueAt", $$v)},expression:"filters.issueAt"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuIssueAt),callback:function ($$v) {_vm.menuIssueAt=$$v},expression:"menuIssueAt"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.filters.issueAt),callback:function ($$v) {_vm.$set(_vm.filters, "issueAt", $$v)},expression:"filters.issueAt"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function () {
                        _vm.menuIssueAt = false
                        _vm.filters.issueAt = null
                      }}},[_vm._v(" Clear ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"label":"State","items":_vm.filterOptions.state,"hide-details":"","outlined":"","dense":""},model:{value:(_vm.filters.state),callback:function ($$v) {_vm.$set(_vm.filters, "state", $$v)},expression:"filters.state"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.fetchInvoices}},[_vm._v("Submit")])],1)],1)]},proxy:true},{key:"item.state",fn:function(ref){
                      var item = ref.item;
return [_c('BillState',{attrs:{"invoice":item}})]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","fab":""},on:{"click":function($event){return _vm.toDetailsPage(item._id)}}},[_c('v-icon',[_vm._v("fas fa-eye")])],1)]}},{key:"footer",fn:function(){return [_c('v-pagination',{attrs:{"length":_vm.totalPage},on:{"input":_vm.fetchInvoices},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }