import axios from 'axios'
import store from '@/store'
import { refreshFirebaseToken } from '@/utils/firebase'
import { refreshSTSToken } from '@/utils/objectStorageService'

axios.defaults.withCredentials = true
axios.defaults.timeout = 600000
axios.defaults.baseURL = process.env.VUE_APP_BASE_API

// on req
axios.interceptors.request.use(
  async (config) => {
    await refreshFirebaseToken()
    await refreshSTSToken()
    config.headers = {
      Authorization: 'Bearer ' + store.state.firebase.token,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
      // 'Content-Type': 'application/json'
    }
    store.dispatch('startLoading')

    // if (!store.state.loading.length) {
    // store.state.loadingActive = true
    // }
    // store.state.loading.push(true)

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// on res
axios.interceptors.response.use(
  (response) => {
    // store.state.loading.pop()
    // if (!store.state.loading.length) {
    store.dispatch('stopLoading')
    // store.state.loadingActive = false
    // }
    return response
  },
  (error) => {
    // store.state.loading.pop()
    // if (!store.state.loading.length) {
    store.dispatch('stopLoading')
    // store.state.loadingActive = false
    // }
    // console.log(error)
    return Promise.reject(error)
  }
)

export default axios
