import store from '@/store'

export default {
  install(
    Vue: {
      prototype: { $oss: { getSignedUrl: (src: string) => Promise<any> } }
    },
    options: any
  ) {
    Vue.prototype.$oss = {
      getSignedUrl: async (src: string) => {
        return await store.state.ossClient.signatureUrl(src)
      }
    }
  }
}
// const ossClient = {
//   install(app: any, options: any) {
//     Vue.prototype.$oss = {
//       getSignedUrl: async (src: string) => {
//         return await store.state.ossClient.signatureUrl(src)
//       }
//     }
//   }
// }
// export default ossClient
