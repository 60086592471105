const DEFAULT_DISCOUNT_TYPE = '0.00' // '1000.00', '10.00%'
const DEFAULT_VAT_TYPE = '0%' // 'none', '7%'
const DEFAULT_WHT_TYPE = 'not_specified' // 'not_specified', 'none', '3%', ...

export default class InvoicePrice {
  private params: any

  public constructor(invoiceParams: any) {
    this.params = invoiceParams
  }

  /**
   * Below here are PRIVATE functions responsible to calculate price
   */

  public itemsGotDifferentVatType() {
    const vatTypes = this.params.invoiceItems.map((item: any) => item.vatType)
    return vatTypes.length > 1 && new Set(vatTypes).size === vatTypes.length
  }

  private calculateDiscount(
    amount: number,
    discountType: string = DEFAULT_DISCOUNT_TYPE
  ) {
    if (!discountType.toString().includes('%')) {
      return parseFloat(discountType)
    }

    const percentDiscount = parseFloat(discountType.replace('%', ''))
    const discountAmount = amount * (percentDiscount / 100)

    return discountAmount
  }

  private calculateVat(
    amountAfterDiscount: number,
    vatType: string = DEFAULT_VAT_TYPE
  ) {
    if (vatType === 'none') {
      return 0
    }

    const percentVat = parseFloat(vatType.replace('%', '')) / 100

    if (this.params.isIncludeVat) {
      return amountAfterDiscount - amountAfterDiscount * (1 / (1 + percentVat))
    }
    return amountAfterDiscount * percentVat
  }

  private calculatePreVat(amountAfterDiscount: number, vatAmount: number) {
    if (this.params.isIncludeVat) {
      return amountAfterDiscount - vatAmount
    }
    return amountAfterDiscount
  }

  private calculateWht(
    preVatAmount: number,
    whtType: string = DEFAULT_WHT_TYPE
  ) {
    if (whtType === 'not_specified' || whtType === 'none') {
      return 0
    }

    const percentWht = parseFloat(whtType.replace('%', '')) / 100
    const whtAmount = preVatAmount * percentWht

    return whtAmount
  }

  /**
   * Below here are PRIVATE functions responsible to calculate item price
   */

  private getItemAmount(item: any) {
    return parseFloat(item.amount)
  }

  private getItemDiscount(item: any) {
    const amount = this.getItemAmount(item)
    return this.calculateDiscount(amount, item.discountType)
  }

  private getItemAmountAfterDiscount(item: any) {
    return this.getItemAmount(item) - this.getItemDiscount(item)
  }

  private getItemVat(item: any) {
    const amountAfterDiscount = this.getItemAmountAfterDiscount(item)
    return this.calculateVat(amountAfterDiscount, item.vatType)
  }

  public getItemPreVatAmount(item: any) {
    const amountAfterDiscount = this.getItemAmountAfterDiscount(item)
    return this.calculatePreVat(amountAfterDiscount, this.getItemVat(item))
  }

  private getItemAmountAfterVat(item: any) {
    return this.getItemPreVatAmount(item) + this.getItemVat(item)
  }

  private getItemWht(item: any) {
    const preVatAmount = this.getItemPreVatAmount(item)
    return this.calculateWht(preVatAmount, item.whtType)
  }

  public getItemTotal(item: any) {
    return this.getItemAmountAfterVat(item) - this.getItemWht(item)
  }

  /**
   * Below here are functions responsible to calculate sum of items price
   */

  private getTotal() {
    return this.params.invoiceItems.reduce(
      (sum: number, item: number) => sum + this.getItemAmount(item),
      0
    )
  }

  private getItemsDiscount() {
    return this.params.invoiceItems.reduce(
      (sum: number, item: number) => sum + this.getItemDiscount(item),
      0
    )
  }

  public getTotalDiscount() {
    return this.params.discount + this.getItemsDiscount()
  }

  private getTotalAfterDiscount() {
    return this.getTotal() - this.getTotalDiscount()
  }

  private getTotalAfterDiscountByItems(items: any[]) {
    const amount = items.reduce(
      (sum, item) => sum + this.getItemAmount(item),
      0
    )
    const itemsDiscount = items.reduce(
      (sum, item) => sum + this.getItemDiscount(item),
      0
    )

    return amount - itemsDiscount - parseFloat(this.params.discount)
  }

  public getVat() {
    if (this.itemsGotDifferentVatType()) {
      return this.params.invoiceItems.reduce(
        (sum: number, item: any) => sum + this.getItemVat(item),
        0
      )
    }

    const amountAfterDiscount = this.getTotalAfterDiscount()

    return this.calculateVat(
      amountAfterDiscount,
      this.params.invoiceItems[0]?.vatType
    )
  }

  private getPreVatAmount() {
    const amountAfterDiscount = this.getTotalAfterDiscount()
    return this.calculatePreVat(amountAfterDiscount, this.getVat())
  }

  public getAmountAfterVat() {
    return this.getPreVatAmount() + this.getVat()
  }

  public getVatExemptedAmount() {
    const vatExemptedItems = this.params.invoiceItems.filter(
      (item: any) => item.vatType === 'none'
    )
    const amountAfterDiscount = this.getTotalAfterDiscountByItems(
      vatExemptedItems
    )

    return amountAfterDiscount > 0 ? amountAfterDiscount : 0
  }

  public getTaxExclusiveAmount() {
    const vatCalculatedItems = this.params.invoiceItems.filter(
      (item: any) => item.vatType !== 'none'
    )
    const amountAfterDiscount = this.getTotalAfterDiscountByItems(
      vatCalculatedItems
    )

    const data = this.calculatePreVat(amountAfterDiscount, this.getVat())

    return data
  }

  public getWht() {
    return this.params.invoiceItems.reduce(
      (sum: number, item: any) => sum + this.getItemWht(item),
      0
    )
  }

  public getGrandTotal() {
    return this.getAmountAfterVat() - this.getWht()
  }
}
