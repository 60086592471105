import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class Pagination extends Vue {
  page = 1
  perPage = 10
  totalPage = 0

  setPagination(meta: any) {
    this.page = meta.currentPage
    this.perPage = meta.perPage
    this.totalPage = Math.ceil(meta.total / meta.perPage)
  }
}
