import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import VueAxios from 'vue-axios'
import axios from './http'
import dayjs from '@/utils/dayjs'
import i18n from './i18n'
// import './sass/variables.scss'
import './sass/main.scss'
import ossClient from '@/plugins/ossClient'
import { get } from 'lodash'
import dotenv from 'dotenv'
dotenv.config()
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}

firebase.initializeApp(firebaseConfig)

Vue.config.productionTip = false
Vue.use(ossClient)
Vue.use(VueAxios, axios)
Vue.mixin({
  methods: {
    THAI_DATE: (date: any) => {
      if (date) return dayjs(date).tz('Asia/Bangkok').format('DD/MM/YYYY')
      else return ''
    },
    THAI_TIME: (time: any) => {
      if (time) {
        return dayjs(time).tz('Asia/Bangkok').format('HH:mm')
      } else {
        return ''
      }
    },
    DATE_TIME: (timeStamp: any) => {
      if (timeStamp) {
        return dayjs(timeStamp).tz('Asia/Bangkok').format('DD/MM/YYYY - HH:mm')
      } else {
        return ''
      }
    },
    DATE_TIME_RELATIVE: (timeStamp: any) => {
      const date: any = new Date(timeStamp)
      const now = new Date()
      const secondsPast = (now.getTime() - date) / 1000
      if (secondsPast < 3600) {
        return (secondsPast / 60).toFixed(0) + ' mins ago'
      }
      if (secondsPast <= 86400) {
        return (secondsPast / 3600).toFixed(0) + ' hrs ago'
      }
      if (secondsPast > 86400) {
        return dayjs(date).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm')
      }
    },
    REMOVE_HTML_TAG(text: string) {
      return text.replace(/<[^>]*>/g, '')
    },
    $get(val: any, path: string, _default: any) {
      return get(val, path, _default)
    },
    GET_SECURED_IMAGE(path: string) {
      return store.state.ossClient.signatureUrl(path)
    },
    I18N(textTh: string, textEng: string) {
      return i18n.locale === 'th' ? textTh : textEng
    }
  }
})
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
