






















































































import { Component, Mixins, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import Pagination from '@/components/Pagination.vue'
import axios from '@/http-no-loading'
import { flashResponseError, flashSuccess } from '@/utils/flashMessage'
import PaginationMixin from '@/mixins/pagination'
import SelectProjectDropdown from '@/components/project/SelectProjectDropdown.vue'

@Component({ components: { Pagination, PageHeader, SelectProjectDropdown } })
export default class News extends Mixins(PaginationMixin) {
  search = ''

  filters = {
    projectId: null
  }

  newsList = [] as {
    content: string
    createdAt: string
    updatedAt: string
    id: string
    image: string[]
    projectId: number
    projectName: string
    title: string
    userId: number
  }[]

  dataTableLoading = false

  headers = [
    // {
    //   text: 'title',
    //   align: 'start',
    //   sortable: false,
    //   value: 'title'
    // },
    { text: 'title', value: 'title', width: '20%' },
    // { text: 'content', value: 'content', width: '20%' },
    // { text: 'image', value: 'image', width: '20%' },
    // { text: 'projectName', value: 'projectName', width: '5%' },
    { text: 'project', value: 'project.name', width: '5%' },
    { text: 'createdAt', value: 'createdAt', width: '5%' },
    { text: 'updatedAt', value: 'updatedAt', width: '5%' },
    { text: 'actions', value: 'actions', width: '5%' }
    // { text: 'id', value: 'id' },
    // { text: 'projectId', value: 'projectId' },
    // { text: 'userId', value: 'userId' }
  ]

  get companyId() {
    return this.$route.params.companyId
  }

  async mounted() {
    await this.fetchNewsList({ pageLoading: true })
  }

  async fetchNewsList(
    option: { pageLoading: boolean } = { pageLoading: false }
  ) {
    const requester = option.pageLoading ? this.$http : axios
    if (!option.pageLoading) this.dataTableLoading = true

    await requester
      .get('/news/', {
        params: {
          page: this.page,
          perPage: 10,
          search: this.search,
          orderField: 'createdAt',
          orderBy: 'desc',
          projectId: this.filters.projectId,
          companyId: this.companyId
        }
      })
      .then((res) => {
        const { data } = res.data
        this.setPagination(res.data.meta)
        this.newsList = data
      })
      .catch(flashResponseError)
      .then(() => (this.dataTableLoading = false))
  }

  toDetailsPage(newsId: string) {
    this.$router.push(`/company/${this.companyId}/news/${newsId}`)
  }

  async deleteNews(_id: string) {
    if (confirm('Are you sure you want to delete this news ?')) {
      await this.$http
        .delete(`/news/${_id}`)
        .then(() => {
          flashSuccess()
          this.fetchNewsList()
        })
        .catch(flashResponseError)
    }
  }

  async searchClicked() {
    this.page = 1
    await this.fetchNewsList()
  }
}
