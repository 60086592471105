






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ components: {} })
export default class BuildingForm extends Vue {
  @Prop({
    default: {
      buildingId: 0,
      name: ''
    },
    required: true
  })
  value!: {
    buildingId: number
    name: string
  }
}
