

















import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import UserForm from '@/components/form/UserForm.vue'

@Component({ components: { UserForm, PageHeader } })
export default class UserCreate extends Vue {
  userData = {
    projectId: '',
    firstName: '',
    lastName: '',
    phoneNumber: '+66',
    email: ''
  }

  mounted() {
    this.userData.projectId = this.$route.params.projectId
  }

  submit() {
    this.$http
      .post('capi/user/user', this.userData)
      .then((res) => {
        this.$router.go(-1)
      })
      .catch((e) => {
        alert(e)
      })
  }
}
