




































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import axiosNoLoader from '@/http-no-loading'

@Component({ components: {} })
export default class RentalContractAddUserDialog extends Vue {
  @Prop({
    default: false,
    required: true
  })
  value!: boolean
  loading= false
  search = ''
  isActive = this.value
  usersArr = [] as UserData[]
  userId = -1

  @Watch('search')
  async searchClicked(v: string) {
    this.loading = true
    this.search = v
    await axiosNoLoader
      .get('/capi/user/user', {
        params: {
          projectId: this.$route.params.projectId,
          skip: 0,
          take: 20,
          search: this.search
        }
      })
      .then((res) => {
        this.usersArr = res.data
      }).finally(() => {
        this.loading = false
      })
  }

  saveClicked() {
    this.$http
      .post(
        `/capi/rental_contract/${this.$route.params.rentalContractId}/user/add`,
        { userId: this.userId }
      )
      .then((res) => {
        console.log(res.data)
      })
      .finally(() => {
        this.$emit('reloadData')
        this.closeClicked()
      })
  }

  closeClicked() {
    this.$emit('input', false)
  }

  @Watch('value')
  changed() {
    this.isActive = this.value
  }
}

interface UserData {
  address: string
  company_phone_number: string
  createdAt: string
  discardedAt: string
  email: string
  externalID: string
  faxNumber: string
  firstName: string
  freshChatRestoreID: string
  id: number
  lastName: string
  newsViewCount: number
  number: string
  phoneNumber: string
  postViewCount: number
  projectId: number
  roleId: number
  state: string
  taxNumber: string
  updatedAt: string
  userId: number
  userTypeId: string
}
