
















import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class Pagination extends Vue {
  @Prop({ default: 0, required: true }) value!: number

  @Emit('back')
  minus() {
    this.$emit('input', this.value - 1)
  }

  @Emit('forward')
  plus() {
    this.$emit('input', this.value + 1)
  }
}
