




































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

@Component({ components: { VueTelInput } })
export default class UserImportDialog extends Vue {
  @Prop({ default: false, required: true }) value!: boolean
  isActive = false

  phoneNumber = ''
  userData = {} as {
    address: string
    company_phone_number: string
    createdAt: string
    discardedAt: string
    email: string
    externalID: string
    faxNumber: string
    firstName: string
    freshChatRestoreID: string
    id: number
    lastName: string
    newsViewCount: number
    number: string
    phoneNumber: string
    postViewCount: number
    state: string
    taxNumber: string
    updatedAt: string
  }

  searchClicked() {
    console.log('search')
    this.$http
      .get(`/capi/user/user/duplicate/${this.phoneNumber.replace(/\s/g, '')}`)
      .then((res) => {
        console.log(res)
        this.userData = res.data
      })
      .catch((e) => {
        alert(e)
      })
  }

  duplicateClicked() {
    console.log('duplicateClicked')
    this.$http
      .post('/capi/user/user/duplicate', {
        userId: this.userData.id,
        projectId: this.$route.params.projectId
      })
      .then((res) => {
        console.log(res)
      })
  }

  closeClicked() {
    this.$emit('input', false)
  }

  @Watch('value')
  changed() {
    this.isActive = this.value
  }
}
