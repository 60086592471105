



































































































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import UploadImages from 'vue-upload-drop-images'
import { Field, FormGroup, TextareaField } from '@/components/field'
import { flashResponseError, flashSuccess } from '@/utils/flashMessage'

@Component({
  components: { PageHeader, UploadImages, FormGroup, Field, TextareaField }
})
export default class ProjectCreate extends Vue {
  project = {
    nameEn: '',
    nameTh: '',
    initial: '',
    phoneNumber: '',
    address: '',
    landmark: '',
    houseNo: '',
    province: '',
    road: '',
    district: '',
    subDistrict: '',
    zipCode: '',
    remark: '',

    isActive: true,
    public: true
  } as { [key: string]: any }

  images = []

  handleChangeImages(files: any) {
    this.images = files
  }

  async createProject() {
    if (!confirm('Are you sure you want to create this project ?')) return false

    const formData = new FormData()
    for (let key in this.project) {
      formData.append(key, this.project[key])
    }
    for (let image of this.images) {
      formData.append('images', image)
    }
    formData.append('companyId', this.$route.params.companyId)

    await this.$http
      .post('/capi/project', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        flashSuccess()
        this.$router.go(-1)
      })
      .catch(flashResponseError)
  }
}
