




import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class BillState extends Vue {
  @Prop() invoice!: any

  get state() {
    if (this.invoice.isOverdued) {
      return 'overdue'
    }

    return this.invoice.state
  }
}
