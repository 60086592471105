










import { Component, Vue } from 'vue-property-decorator'
import { Select } from '@/components/field'

@Component({ components: { Select }, props: ['value', 'disabled'] })
export default class SelectProjectDropdown extends Vue {
  get companyId() {
    return this.$route.params.companyId
  }

  get projectOptions() {
    const defaultOption = {
      text: '-',
      value: null
    }
    const projectOptions = this.$store.getters
      .projects(this.companyId)
      .map((project: any) => {
        return {
          text: project.nameTh + project.nameEn,
          value: project.id
        }
      })
    return [defaultOption, ...projectOptions]
  }

  onChange(v: any) {
    this.$emit('input', v)
  }
}
