

















































































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import { flashResponseError, flashSuccess } from '@/utils/flashMessage'
import SelectProjectDropdown from '@/components/project/SelectProjectDropdown.vue'

@Component({ components: { PageHeader, SelectProjectDropdown } })
export default class PaymentMethod extends Vue {
  projectPaymentMethods = [] as any
  projectId = null

  mounted() {}

  fetchPaymentMethod() {
    if (!this.projectId) return (this.projectPaymentMethods = [])

    this.$http
      .get(`/capi/paymentMethod/project/${this.projectId}`)
      .then((res) => (this.projectPaymentMethods = res.data))
      .catch(flashResponseError)
  }

  updateProjectPaymentMethod(projectPaymentMethod: any) {
    this.$http
      .put(`/capi/paymentMethod/project/${this.projectId}`, {
        paymentMethodId: projectPaymentMethod.paymentMethodId,
        isActive: projectPaymentMethod.isActive,
        feePercentage: projectPaymentMethod.feePercentage,
        feePrice: projectPaymentMethod.feePrice
      })
      .then(() => flashSuccess())
      .catch(flashResponseError)
  }
}
