






















































import { Component, Vue } from 'vue-property-decorator'
import Editor from '@/components/Editor.vue'
import { flashResponseError, flashSuccess } from '@/utils/flashMessage'
import PageHeader from '@/components/PageHeader.vue'
import SelectProjectDropdown from '@/components/project/SelectProjectDropdown.vue'

@Component({ components: { Editor, PageHeader, SelectProjectDropdown } })
export default class NewsDetails extends Vue {
  news = {} as {
    content: string
    createdAt: string
    isActive: boolean
    projectId: number
    title: string
    updatedAt: string
    userId: number
    _id: string
  }
  editActive = false
  carouselModel = 0

  async mounted() {
    await this.getData()
  }

  async getData() {
    await this.$http
      .get(`/news/${this.$route.params.newsId}`)
      .then((res) => {
        this.news = res.data
      })
      .catch((err) => {
        console.log(err)
      })
  }

  cancelClicked() {
    this.editActive = !this.editActive
    this.getData()
  }

  async saveClicked() {
    await this.$http
      .patch(`/news/${this.news._id}`, this.news)
      .then(() => {
        this.$router.go(-1)
      })
      .catch((err) => {
        alert(err)
      })
  }

  async deleteNews(_id: string) {
    if (confirm('Are you sure you want to delete this news ?')) {
      await this.$http
        .delete(`/news/${this.news._id}`)
        .then(() => {
          flashSuccess()
          this.$router.go(-1)
        })
        .catch(flashResponseError)
    }
  }
}
