



























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({ components: {} })
export default class DatePickerMenu extends Vue {
  @Prop({ default: '', required: true }) value!: string
  @Prop({ default: '', required: true }) label!: string

  menu = false

  changed(v: any) {
    this.$emit('input', v)
  }
}
