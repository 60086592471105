


















































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import DialogConfirmDanger from '@/components/dialog/DialogConfirmDanger.vue'
import BillState from '@/components/tag/BillState'
import THBText from 'thai-baht-text'
import _ from 'lodash'

@Component({ components: { PageHeader, DialogConfirmDanger, BillState } })
export default class InvoiceDetails extends Vue {
  invoice = {} as any
  isVoidModalOpen = false

  get invoiceId() {
    return this.$route.params.invoiceId
  }

  get companyId() {
    return this.$route.params.companyId
  }

  calcTotal(inv: any) {
    let total = inv.grandTotalCents
    if (inv.creditNotes.length) {
      inv.creditNotes.forEach((v: any) => {
        total += v.grandTotalCents
      })
    }
    return total
  }

  THBText(v: any) {
    return THBText(v)
  }

  async mounted() {
    await this.fetchInvoice()
  }

  async fetchInvoice() {
    await this.$http
      .get(`/invoice/${this.invoiceId}`)
      .then((res) => (this.invoice = res.data))
      .catch(console.log)
  }

  async voidInvoice() {
    await this.$http
      .post(`/invoice/${this.invoiceId}/void`)
      .then(() => {
        this.invoice.state = 'voided'
        this.isVoidModalOpen = false
      })
      .catch(console.log)
  }
}
