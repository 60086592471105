import axios from 'axios'
import store from '@/store'
import { refreshSTSToken } from '@/utils/objectStorageService'
import { refreshFirebaseToken } from '@/utils/firebase'

const axiosNoLoader = axios.create()

axiosNoLoader.defaults.timeout = 600000
axiosNoLoader.defaults.baseURL = process.env.VUE_APP_BASE_API

// on req
axiosNoLoader.interceptors.request.use(
  async (config) => {
    await refreshSTSToken()
    await refreshFirebaseToken()
    config.headers = {
      Authorization: 'Bearer ' + store.state.firebase.token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// on res
axiosNoLoader.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    console.log(error)
    return Promise.reject(error)
  }
)

export default axiosNoLoader
