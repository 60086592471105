




























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class DialogConfirmDanger extends Vue {
  @Prop({ default: 'Are you sure you want to delete this?' })
  message?: string

  @Prop({ default: false })
  isOpen!: boolean
}
