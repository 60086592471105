




import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({ components: {} })
export default class BaseImage extends Vue {
  @Prop({ default: '', required: true }) src!: string
  signedUrl = ''
  get getSignedUrl() {
    return this.signedUrl
  }
  async mounted() {
    this.signedUrl = await this.$store.state.ossClient.signatureUrl(this.src)
  }
}
