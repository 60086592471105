































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import RentalContractForm from '@/components/form/RentalContractForm.vue'
import RentalContractUser from '@/components/rentalContract/RentalContractUser.vue'

@Component({
  components: { RentalContractUser, RentalContractForm, PageHeader }
})
export default class RentalContractShow extends Vue {
  rentalContractData = {} as any
  tab = 0

  mounted() {
    this.getData()
  }

  getData() {
    this.$http
      .get(`/capi/rental_contract/${this.$route.params.rentalContractId}`)
      .then((res) => {
        this.rentalContractData = res.data
      })
  }
}

interface RentalContractData {
  id: string
  name: string
  address: string
  taxNumber: string
  faxNumber: string
  phoneNumber: string
  startDate: string
  endDate: string
  deletedAt: string
  projectId: number
  state: string
  integrationType: string
  referenceNo: string
  createdAt: string
  updatedAt: string
  user: {
    id: number
    firstName: string
    lastName: string
    email: string
    state: string
    discardedAt: string
    number: string
    company_phone_number: string
    phoneNumber: string
    address: string
    faxNumber: string
    taxNumber: string
    newsViewCount: number
    postViewCount: number
    freshChatRestoreID: string
    externalID: string
    createdAt: string
    updatedAt: string
  }
  rentalContractUnits: [
    {
      id: string
      createdAt: string
      updatedAt: string
      unit: {
        id: string
        name: string
        deletedAt: string
        currentRentalContract: string
        isActive: boolean
        createdAt: string
        updatedAt: string
        buildingFloor: {
          id: string
          name: string
          isActive: boolean
          floorLevel: number
          createdAt: string
          updatedAt: string
          building: {
            id: string
            name: string
            isActive: boolean
            createdAt: string
            updatedAt: string
          }
        }
      }
    }
  ]
  project: {
    id: number
    name: string
    public: boolean
    defaultCurrency: string
    phoneNumber: string
    branchName: string
    isActive: boolean
    createdAt: string
    updatedAt: string
    company: {
      id: string
      name: string
      address: string
      taxNumber: string
      invoiceRemark: string
      invoiceRemark2: string
      invoiceRemark3: string
      isActive: boolean
      createdAt: string
      updatedAt: string
    }
  }
  rentalContractUserRole: [
    {
      id: string
      userToken: string
      createdAt: string
      updatedAt: string
      user: {
        id: number
        firstName: string
        lastName: string
        email: string
        state: string
        discardedAt: string
        number: string
        company_phone_number: string
        phoneNumber: string
        address: string
        faxNumber: string
        taxNumber: string
        newsViewCount: number
        postViewCount: number
        freshChatRestoreID: string
        externalID: string
        createdAt: string
        updatedAt: string
      }
      role: {
        id: number
        code: string
        descThai: string
        descEng: string
        isAdminRole: boolean
        createdAt: string
        updatedAt: string
      }
    }
  ]
}
