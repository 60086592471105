
















































import { Component, Prop, Vue } from 'vue-property-decorator'
import RentalContractUserRoleEditDialog from '@/components/rentalContract/RentalContractUserRoleEditDialog.vue'
import RentalContractAddUserDialog from '@/components/rentalContract/RentalContractAddUserDialog.vue'
import DialogConfirmDanger from '@/components/dialog/DialogConfirmDanger.vue'

@Component({
  components: {
    DialogConfirmDanger,
    RentalContractAddUserDialog,
    RentalContractUserRoleEditDialog
  }
})
export default class RentalContractUser extends Vue {
  @Prop({
    default: [],
    required: true
  })
  value!: RentalContractUserData[]

  headers = [
    { text: 'firstName', value: 'user.firstName' },
    { text: 'lastName', value: 'user.lastName' },
    { text: 'email', value: 'user.email' },
    { text: 'phoneNumber', value: 'user.phoneNumber' },
    { text: 'role', value: 'role.descThai' },
    { text: 'actions', value: 'actions' }
  ]

  editRoleDialog = {
    isActive: false,
    rentalContractUserId: -1,
    userId: -1
  }

  addUserDialog = {
    isActive: false
  }

  removeUserData = {
    isActive: false,
    userId: -1
  }

  reloadData() {
    this.$emit('reloadData')
  }

  addUser() {
    this.addUserDialog.isActive = true
  }

  removeUser() {
    this.$http
      .post(
        `/capi/rental_contract/${this.$route.params.rentalContractId}/user/remove`,
        { userId: this.removeUserData.userId }
      )
      .then((res) => {})
      .finally(() => {
        this.$emit('reloadData')
        this.removeUserData.isActive = false
      })
  }

  editBtnClicked(item: RentalContractUserData) {
    this.editRoleDialog.rentalContractUserId = parseInt(item.id)
    this.editRoleDialog.isActive = true
    this.editRoleDialog.userId = item.user.id
  }

  deleteBtnClicked(item: RentalContractUserData) {
    this.removeUserData.isActive = true
    this.removeUserData.userId = item.user.id
  }
}

interface RentalContractUserData {
  id: string
  userToken: string
  createdAt: string
  updatedAt: string
  user: {
    id: number
    firstName: string
    lastName: string
    email: string
    state: string
    discardedAt: null
    number: null
    company_phone_number: string
    phoneNumber: string
    address: string
    faxNumber: string
    taxNumber: string
    newsViewCount: number
    postViewCount: number
    freshChatRestoreID: string
    externalID: string
    createdAt: string
    updatedAt: string
  }
  role: {
    id: number
    code: string
    descThai: string
    descEng: string
    isAdminRole: boolean
    createdAt: string
    updatedAt: string
  }
}
