

























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import SelectProjectDropdown from '@/components/project/SelectProjectDropdown.vue'

@Component({ components: { SelectProjectDropdown } })
export default class RentalContractForm extends Vue {
  @Prop({
    default: {},
    required: true
  })
  value!: any

  get userFullName() {
    return this.value.user?.firstName + ' ' + this.value.user?.lastName
  }

  @Prop({ default: false }) disabled!: boolean

  @Watch('value')
  changed() {
    this.$emit('input', this.value)
  }
}
