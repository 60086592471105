





































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import DatePickerMenu from '@/components/DatePickerMenu.vue'
import axiosNoLoader from '@/http-no-loading'
import { flashResponseError, flashSuccess } from '@/utils/flashMessage'
import SelectProjectDropdown from '@/components/project/SelectProjectDropdown.vue'

@Component({
  components: { DatePickerMenu, PageHeader, SelectProjectDropdown }
})
export default class RentalContractCreate extends Vue {
  rentalContract = {
    name: '',
    address: '',
    taxNumber: '',
    faxNumber: '',
    phoneNumber: '',
    referenceNo: '',
    startDate: '',
    endDate: ''
  }

  users = []
  selectedUser: any = {}
  units = []
  selectedUnits = []
  searchUserText = ''
  searchUnitText = ''

  @Watch('searchUnitText')
  searchUnit() {
    this.$http
      .get('/capi/unit/', {
        params: {
          projectId: this.$route.params.projectId,
          skip: 0,
          take: 20,
          search: this.searchUnitText,
          filter: { date: 0 }
        }
      })
      .then((res) => {
        this.units = res.data as any
      })
  }

  @Watch('searchUserText')
  searchUser() {
    axiosNoLoader
      .get(`/capi/user/user`, {
        params: {
          projectId: this.$route.params.projectId,
          skip: 0,
          take: 20,
          search: this.searchUserText
        }
      })
      .then((res) => {
        this.users = res.data
      })
  }

  createRentalContract() {
    const params = {
      ...this.rentalContract,
      startDate: this.parseDate(this.rentalContract.startDate),
      endDate: this.parseDate(this.rentalContract.endDate),
      userId: this.selectedUser?.id || null,
      unitIds: this.selectedUnits.map((unit: any) => unit.unitId)
    }

    this.$http
      .post(`/capi/rental_contract`, params)
      .then(() => {
        flashSuccess()
        this.$router.go(-1)
      })
      .catch(flashResponseError)
  }

  parseDate(dateString: string) {
    return dateString
      ? new Date(new Date(dateString).setHours(0, 0, 0, 0)).toISOString()
      : null
  }
}
