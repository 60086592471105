






































































































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import { flashResponseError, flashSuccess } from '@/utils/flashMessage'
import { EditableField, Label } from '@/components/field'
import UserState from '@/components/tag/UserState'

@Component({ components: { PageHeader, EditableField, Label, UserState } })
export default class UserShow extends Vue {
  editMode = false

  userData = {} as {
    address: string
    company_phone_number: string
    createdAt: Date
    discardedAt: Date
    email: string
    externalID: string
    faxNumber: string
    firstName: string
    freshChatRestoreID: string
    id: number
    lastName: string
    newsViewCount: number
    number: string
    phoneNumber: string
    postViewCount: number
    state: string
    taxNumber: string
    updatedAt: Date
    userTypeId: string
  }

  get userId() {
    return this.$route.params.userId
  }

  get companyId() {
    return this.$route.params.companyId
  }

  async mounted() {
    await this.fetchUser()
    if (this.$route.query.editMode) {
      this.editMode = true
    }
  }

  fetchUser() {
    this.$http.get(`/capi/user/${this.userId}`).then((res) => {
      this.userData = res.data
    })
  }

  updateUser() {
    this.$http
      .patch(`capi/user/user/${this.userId}`, this.userData)
      .then((res) => {
        flashSuccess()
        this.editMode = false
      })
      .catch(flashResponseError)
  }

  removeUserFromProject() {
    alert('Not working right now') // NEED TO REFACTOR
    // if (confirm('Are you sure you want to remove user from this project ?')) {
    //   this.$http
    //     .delete(`/capi/user/${this.userId}/project`, {
    //       data: {
    //         projectId: this.projectId
    //       }
    //     })
    //     .then(() => {
    //       flashSuccess()
    //       this.$router.replace(`/company/${this.companyId}/user`)
    //     })
    //     .catch(flashResponseError)
    // }
  }
}
