



















import { Component, Vue } from 'vue-property-decorator'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import NavBar from '@/components/NavBar.vue' // @ is an alias to /src
import AppPreloadData from '@/components/AppPreloadData.vue'

@Component({ components: { NavBar, Loading, AppPreloadData } })
export default class App extends Vue {
  get containerClasses() {
    if (this.$route.fullPath != '/login') {
      return 'ma-0 pa-3'
    } else {
      return 'ma-0 pa-0'
    }
  }
}
