




import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({ components: {} })
export default class AppPreloadData extends Vue {
  async mounted() {
    await this.loadCompanies()
  }

  async loadCompanies() {
    const response = await this.$http.get('/capi/company')
    this.$store.commit('companiesLoaded', response.data)
  }
}
