// import { createI18n } from 'vue-i18n'
// // import createI18n from '../node_modules/vue-i18n/types'
import en from './locales/en.json'
import th from './locales/th.json'
//
// const languages = {
//   en: en,
//   th: th
// }
//
// const messages = Object.assign(languages)
//
// export default createI18n({
//   locale: 'th',
//   fallbackLocale: 'th',
//   messages: messages,
//   silentFallbackWarn: true
// })
//

import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'

Vue.use(VueI18n)

// function loadLocaleMessages(): LocaleMessages {
//   const locales = require.context(
//     "./locales",
//     true,
//     /[A-Za-z0-9-_,\s]+\.yaml$/i
//   );
//   const messages: LocaleMessages = {};
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i);
//     if (matched && matched.length > 1) {
//       const locale = matched[1];
//       messages[locale] = locales(key);
//     }
//   });
//   // console.log(messages);
//   return messages;
// }

const languages = {
  en: en,
  th: th
}

const messages = Object.assign(languages)

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: messages,
  silentFallbackWarn: true
})
