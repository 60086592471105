

































































































import { Component, Vue } from 'vue-property-decorator'
import loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import DialogLogOut from '@/components/dialog/DialogLogOut.vue'
// import * as vuetify from 'vuetify/src/presets/default/index'
import vuetify from '@/plugins/vuetify'
@Component({ components: { loading, DialogLogOut } })
export default class NavBar extends Vue {
  Cvuetify = vuetify
  drawer = false
  dialogLogOut = false
  language = {
    title: 'Languages',
    isActive: true,
    items: [{ name: 'Thai' }, { name: 'English' }]
  }
  get companyId() {
    return this.$route.params.companyId
  }

  get links() {
    return [
      {
        to: `/company/${this.companyId}/overview`,
        icon: 'fas fa-home',
        text: 'Overview'
      },
      {
        icon: 'fas fa-bars',
        text: 'CMS',
        subLinks: [
          {
            text: 'News',
            to: `/company/${this.companyId}/news`,
            icon: 'fas fa-newspaper'
          }
          // {
          //   text: 'Post',
          //   to: `/company/${this.companyId}/post`,
          //   icon: 'fas fa-address-card'
          // }
        ]
      },
      {
        icon: 'fas fa-user-circle',
        text: 'CRM',
        subLinks: [
          {
            text: 'unit',
            to: `/company/${this.companyId}/unit`,
            icon: 'fas fa-building'
          },
          {
            text: 'rental contract',
            to: `/company/${this.companyId}/rental_contract`,
            icon: 'fas fa-file-contract'
          },
          {
            text: 'bill',
            to: `/company/${this.companyId}/invoice`,
            icon: 'fas fa-receipt'
          },
          {
            text: 'admin',
            to: `/company/${this.companyId}/admin`,
            icon: 'fas fa-users'
          },
          {
            text: 'user',
            to: `/company/${this.companyId}/user`,
            icon: 'fas fa-users'
          }
        ]
      },
      {
        icon: 'fas fa-cog',
        text: 'Settings',
        subLinks: [
          {
            text: 'payment method',
            to: `/company/${this.companyId}/paymentMethod`,
            icon: 'fas fa-credit-card'
          }
        ]
      }
    ]
  }
  changeLocale(locale: any) {
    if (locale === 'English') this.$i18n.locale = 'en'
    else this.$i18n.locale = 'th'
  }
}
