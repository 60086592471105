

























import { Component, Prop, Vue } from 'vue-property-decorator'
import firebase from 'firebase/app'
@Component({ components: {} })
export default class DialogLogOut extends Vue {
  @Prop({ default: false }) value!: boolean
  closeDialog = () => {
    this.$emit('close')
  }
  async logout() {
    await firebase.auth().signOut()
    this.closeDialog()
    this.$router.push('/login')
  }
}
