

























































import { Component, Mixins } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import PaginationMixin from '@/mixins/pagination'
import { flashResponseError } from '@/utils/flashMessage'
import SelectProjectDropdown from '@/components/project/SelectProjectDropdown.vue'

@Component({ components: { PageHeader, SelectProjectDropdown } })
export default class RentalContract extends Mixins(PaginationMixin) {
  page = 1
  search = ''
  dataTableLoading = false

  filters = {
    projectId: null
  }

  headers = [
    // { text: 'id', value: 'id' },
    { text: 'name', value: 'name' },
    { text: 'address', value: 'address' },
    { text: 'phoneNumber', value: 'phoneNumber' },
    { text: 'referenceNo', value: 'referenceNo' },
    { text: 'taxNumber', value: 'taxNumber' },
    { text: 'state', value: 'state' },
    // { text: 'userId', value: 'userId' },
    { text: 'actions', value: 'actions' }

    // { text: 'amount', value: 'amount' },
    // { text: 'endDate', value: 'endDate' },
    // { text: 'integrationType', value: 'integrationType' },
    // { text: 'number', value: 'number' },
    // { text: 'projectId', value: 'projectId' },
    // { text: 'rentalContractId', value: 'rentalContractId' },
    // { text: 'startDate', value: 'startDate' }
  ]

  rentalContractArr = [] as {
    address: string
    amount: any
    endDate: any
    faxNumber: string
    id: string
    integrationType: any
    name: string
    number: any
    phoneNumber: string
    projectId: string
    rentalContractId: any
    startDate: any
    state: string
    taxNumber: string
    userId: number
  }[]

  get companyId() {
    return this.$route.params.companyId
  }

  mounted() {
    this.fetchRentalContracts()
  }

  async fetchRentalContracts() {
    this.dataTableLoading = true

    return this.axios
      .get('/capi/rental_contract', {
        params: {
          page: this.page,
          perPage: 10,
          search: this.search,
          orderField: 'createdAt',
          orderBy: 'desc',
          projectId: this.filters.projectId,
          companyId: this.companyId
        }
      })
      .then((res) => {
        const { data } = res.data
        this.setPagination(res.data.meta)
        this.rentalContractArr = data
      })
      .catch(flashResponseError)
      .then(() => (this.dataTableLoading = false))
  }

  toCreatePage() {
    this.$router.push(`/company/${this.companyId}/rental_contract/create`)
  }

  toDetailsPage(id: number) {
    this.$router.push(`/company/${this.companyId}/rental_contract/${id}`)
  }
}
