




























import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import { flashResponseError, flashSuccess } from '@/utils/flashMessage'
import CompanyForm from '@/components/form/CompanyForm.vue'
import FormCard from '@/components/FormCard.vue'

@Component({
  components: {
    PageHeader,
    CompanyForm,
    FormCard
  }
})
export default class CompanyCreate extends Vue {
  company = {
    name: '',
    address: '',
    taxNumber: '',
    remark: ''
  } as { [key: string]: any }

  images = []

  handleChangeImages(files: any) {
    this.images = files
  }

  async createCompany() {
    if (!confirm('Are you sure you want to create this company ?')) return false

    const formData = new FormData()
    for (let key in this.company) {
      formData.append(key, this.company[key])
    }
    for (let image of this.images) {
      formData.append('images', image)
    }

    await this.$http
      .post('/capi/company', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        flashSuccess()
        this.$router.go(-1)
      })
      .catch(flashResponseError)
  }
}
