














import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import UnitForm from '@/components/form/UnitForm.vue'

@Component({ components: { UnitForm, PageHeader } })
export default class UnitCreate extends Vue {
  unitCreateData = {
    buildingId: 0,
    floorId: 0,
    name: ''
  }

  createClicked() {
    this.$http
      .post(`capi/unit`, this.unitCreateData, {
        params: {
          floorId: this.unitCreateData.floorId
        }
      })
      .then(() => {
        this.$router.go(-1)
      })
      .catch((err) => {
        alert(err)
      })
  }
}
