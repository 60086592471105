




















































































































import { Component, Vue } from 'vue-property-decorator'
import firebase from 'firebase/app'
import store from '@/store'

@Component({ components: {} })
export default class Login extends Vue {
  userData = { email: '', password: '' }
  valid = true
  emailRules = [
    (v: any) => !!v || 'E-mail is required',
    (v: any) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
  ]
  policyCheckbox = false

  async loginClicked() {
    if ((this.$refs.loginForm as any).validate()) {
      store.dispatch('startLoading')

      await firebase
        .auth()
        .signInWithEmailAndPassword(this.userData.email, this.userData.password)
        .then((res) => {
          this.$router.push('/')
        })
        .catch((err) => {
          console.log(err)
          store.dispatch('stopLoading')
        })

      store.dispatch('stopLoading')
    }
  }
}
