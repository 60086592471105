


























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ components: {} })
export default class FloorForm extends Vue {
  @Prop({
    default: {
      buildingId: 0,
      name: ''
    },
    required: true
  })
  value!: {
    buildingId: number
    name: string
  }

  @Prop({ default: false }) disabled!: boolean

  buildings = [] as {
    buildingId: string
    buildingName: string
    floorCount: string
    isActive: boolean
  }[]

  mounted() {
    this.$http
      .get(`/capi/building`, {
        params: {
          projectId: this.$route.params.projectId
        }
      })
      .then((res) => {
        this.buildings = res.data
      })
  }
}
