import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(timezone)
dayjs.extend(LocalizedFormat)
dayjs.extend(utc)

export default dayjs
