











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ props: ['value'] })
export default class TextareaField extends Vue {}
