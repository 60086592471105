







































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import DialogConfirmDanger from '@/components/dialog/DialogConfirmDanger.vue'
import BillState from '@/components/tag/BillState'
import { flashResponseError } from '@/utils/flashMessage'
import _ from 'lodash'
import {
  toMoney,
  forceNumber,
  forceNumberAndPercent
} from '@/utils/textConverter'
import InvoicePrice from '@/utils/invoicePrice'
import THBText from 'thai-baht-text'
import router from '@/router'

@Component({ components: { PageHeader, DialogConfirmDanger, BillState } })
export default class Invoice extends Vue {
  form: any = {
    source: 'manual',
    invoiceCompany: {},
    invoiceCustomer: {},
    rentalContractId: null,
    issueAt: null,
    dueAt: null,
    isIncludeVat: false,
    invoiceItems: [],
    discount: 0,
    remark: null,
    adminRemark: null
  }

  openIssueAt = false
  openDueAt = false

  rentalContracts = []

  projectName = {
    nameTh: '',
    nameEn: ''
  }
  unitName = ''

  price = new InvoicePrice(this.form)

  get companyId() {
    return this.$route.params.companyId
  }

  async mounted() {
    this.handleAddItem()
    this.fetchRentalContractsDropdown()
  }

  THBText(v: any) {
    return THBText(v)
  }

  fetchRentalContractsDropdown() {
    this.$http
      .get('/capi/rental_contract/dropDown', {
        params: {
          projectId: null
        }
      })
      .then((res) => (this.rentalContracts = res.data))
      .catch(flashResponseError)
  }

  createInvoice() {
    this.$http
      .post('/invoice', this.form)
      .then((res) =>
        this.$router.push(
          `/company/${this.companyId}/invoice/${res.data.insertedId}`
        )
      )
      .catch(flashResponseError)
  }

  handleRentalContractChanged() {
    const rentalContract: any = this.rentalContracts.find(
      (_contract: any) => _contract.id === this.form.rentalContractId
    )

    if (rentalContract) {
      this.form.invoiceCustomer.name = rentalContract.name
      this.form.invoiceCustomer.address = rentalContract.address
      this.form.invoiceCustomer.taxNumber = rentalContract.taxNumber
      this.form.invoiceCustomer.phoneNumber = rentalContract.phoneNumber
      this.form.invoiceCustomer.faxNumber = rentalContract.faxNumber

      this.projectName.nameEn = rentalContract.project?.nameEn
      this.projectName.nameTh = rentalContract.project?.nameTh
      this.unitName = _(rentalContract.rentalContractUnits)
        .flatMap((contract) => contract?.unit)
        .map('name')
        .value()

        .join(' / ')
    }
  }

  handleRemoveItem(index: number) {
    if (this.form.invoiceItems.length > 1) {
      this.form.invoiceItems = this.form.invoiceItems.filter(
        (item: any, i: number) => index !== i
      )
    }
  }

  handleAddItem() {
    this.form.invoiceItems.push({
      description: '',
      description2: '',
      discountIsPercent: false,
      discountType: '0.00',
      amount: '0.00',
      vatType: '7%',
      whtType: 'not_specified'
    })
  }

  toMoney(v: any) {
    return toMoney(v)
  }

  reformatItemAmount(index: number) {
    this.form.invoiceItems[index].amount = forceNumber(
      this.form.invoiceItems[index].amount
    )
  }

  reformatDiscount(index: number, isPercent: boolean) {
    const discountType = this.form.invoiceItems[index].discountType.replace(
      '%',
      ''
    )

    if (isPercent) {
      this.form.invoiceItems[index].discountType = forceNumberAndPercent(
        discountType + '%'
      )
    } else {
      this.form.invoiceItems[index].discountType = forceNumber(
        forceNumber(discountType)
      )
    }
  }
}
