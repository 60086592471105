





















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ components: {} })
export default class PageHeader extends Vue {
  @Prop({ default: '' }) title!: string
  @Prop({ default: '' }) subTitle!: string
  @Prop() withBackBtn!: boolean | false
}
