



























































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import UserImportDialog from '@/components/user/UserImportDialog.vue'
import SelectProjectDropdown from '@/components/project/SelectProjectDropdown.vue'
import { flashResponseError } from '@/utils/flashMessage'

@Component({
  components: { UserImportDialog, PageHeader, SelectProjectDropdown }
})
export default class User extends Vue {
  usersList = [] as {
    id: number
    firstName: string
    lastName: string
    email: string
    state: string
    discardedAt: null | string
    number: null | string
    company_phone_number: null | string
    phoneNumber: string
    address: null | string
    faxNumber: null | string
    taxNumber: null | string
    newsViewCount: number
    postViewCount: number
    freshChatRestoreID: null | string
    externalID: string
    createdAt: string
    updatedAt: string
    userTypeId: string
  }[]

  headers = [
    { text: 'id', value: 'id' },
    { text: 'firstName', value: 'firstName' },
    { text: 'lastName', value: 'lastName' },
    { text: 'phoneNumber', value: 'phoneNumber' },
    { text: 'email', value: 'email' },
    { text: 'status', value: 'state' },
    // { text: 'createdAt', value: 'createdAt' },
    // { text: 'updatedAt', value: 'updatedAt' },
    { text: 'actions', value: 'actions' }
  ]

  search = ''
  page = 1
  userImport = {
    isActive: false
  }
  filters = {
    projectId: null
  }

  get companyId() {
    return this.$route.params.companyId
  }

  // mounted() {
  //   this.fetchUsers()
  // }

  fetchUsers() {
    this.$http
      .get('/capi/user/user', {
        params: {
          skip: (this.page - 1) * 20,
          take: 20,
          search: this.search,
          companyId: this.companyId,
          projectId: this.filters.projectId
        }
      })
      .then((res) => {
        this.usersList = res.data as any
      })
      .catch(flashResponseError)
  }

  searchClicked() {
    console.log('search')
  }

  createBtnClicked() {
    this.$router.push(`/company/${this.companyId}/user/create`)
  }

  importBtnClicked() {
    this.userImport.isActive = true
    //TODO import user dialog
    // this.$router.push(`/project/${this.$route.params.projectId}/user/import`)
  }

  detailsBtnClicked(id: number) {
    this.$router.push(`/company/${this.companyId}/user/${id}`)
  }

  editBtnClicked(id: number) {
    this.$router.push(`/company/${this.companyId}/user/${id}?editMode=1`)
  }
}
